<template>
    <header class="app-header">

        <div class="container">

            <router-link to="/"><img class="logo" src="/img/logo-continental.svg" alt="Logo continental"></router-link>
            
            <nav class="row-start" :show="showmenu">
                <router-link to="/" class="home"><img src="/img/logo-casa.svg" alt="Home"></router-link>
                <router-link :to="$t('links.quienessomos')">Quiénes somos</router-link>
                <router-link :to="$t('links.recambios')">Recambios</router-link>
                <router-link :to="$t('links.tacografos')">Tacógrafos y telématica</router-link>
                <router-link :to="$t('links.saladeprensa')">Salas de prensa</router-link>
                <router-link :to="$t('links.contacto')">Contacto</router-link>
                <a class="close" @click="showmenu = !showmenu">✕</a>
            </nav>

            <button class="menu" @click="showmenu = !showmenu">
                <span></span><span></span>
            </button>
            
        </div>

    </header>
</template>


<script>
    export default{
        name : 'app-header',
        data:() => ({
            showmenu : false
        }),
        watch : {
            '$route'(){
                this.showmenu = false;
            }
        }
    }
</script>