import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

import translations from '@/translations';

let links = translations.es.links;

const routes = [
  {
    path: links.quienessomos,
    name: 'quienes-somos',
    component: () => import(/* webpackChunkName: "quienes-somos" */ '../views/quienes-somos.vue')
  },
  {
    path: links.contacto,
    name: 'contacto',
    component: () => import(/* webpackChunkName: "contacto" */ '../views/contacto.vue')
  },
  {
    path: links.saladeprensa,
    name: 'sala-de-prensa',
    component: () => import(/* webpackChunkName: "sala-de-prensa" */ '../views/sala-de-prensa.vue')
  },
  {
    path: `${links.saladeprensa}/:id?`,
    name: 'ficha-prensa',
    component: () => import(/* webpackChunkName: "ficha-prensa" */ '../views/ficha-prensa.vue')
  },
  {
    path: links.cookies,
    name: 'cookies',
    component: () => import(/* webpackChunkName: "cookies" */ '../views/cookies.vue')
  },
  {
    path: links.avisolegal,
    name: 'aviso-legal',
    component: () => import(/* webpackChunkName: "aviso-legal" */ '../views/aviso-legal.vue')
  },
  {
    path: links.terminos,
    name: 'terminos',
    component: () => import(/* webpackChunkName: "terminos-de-uso" */ '../views/terminos-de-uso.vue')
  },
  {
    path: links.declaracionprivacidad,
    name: 'declaracionprivacidad',
    component: () => import(/* webpackChunkName: "declaracion-de-privacidad" */ '../views/declaracion-de-privacidad.vue')
  },
  {
    
    path: '/:id?',
    name: 'Home',
    component: Home
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
