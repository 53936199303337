<template>
  <div id="app">
    <app-header></app-header>
    <router-view/>
    <app-footer></app-footer>
    </div>
  </template>

  <style lang="scss">
  @import '@/assets/css/colors.scss';
  @import '@/assets/css/style.scss';
  @import '@/assets/css/responsive.scss';
  @import '@/assets/css/font-awesome.scss';
</style>


<script>

  import footer from '@/components/footer'
  import header from '@/components/header'

  export default{
    name : "app",
    components : {
      'app-footer' : footer,
      'app-header' : header
    }

  }
</script>