<template>

    <div class="aftermarket" id="recambios">

        <div class="container row-between">
            <h2 class="title">Todo para el aftermarket</h2>
      
        </div>

        <slick ref="slick" :options="slickOptions">
            <div class="slide">

                <div class="container">

                    <nav class="rrss-nav row-start">
                        <a href="https://www.facebook.com/continentalautomotivespain/" target="_blank"><i class="fa fa-facebook"></i></a>
                        <a href="https://twitter.com/PrensaVDO" target="_blank"><i class="fa-brands fa-x-twitter"></i></a>
                        <a href="https://www.youtube.com/user/ContiVDO" target="_blank"><i class="fa fa-youtube"></i></a>
                        <a href="https://www.linkedin.com/company/vdo-espana/" target="_blank"><i class="fa fa-linkedin"></i></a>
                    </nav>

                    <div class="imagen" data="movilidad">
                        <div class="texto-imagen">
                            <p>La movilidad del futuro en un solo proveedor. Disponemos de la solución adecuada para las
                                necesidades de nuestros clientes ofreciendo una amplia gama de productos, servicios y
                                soluciones de alta tecnología y la misma alta calidad que un fabricante de equipos
                                originales.</p>
                        </div>
                    </div>
                    <div class="texto column-between jcfs">

                        <h2>Nuestras marcas</h2>

                        <img src="/img/logo-continental-blanco.svg" alt="Continental">

                        <img src="/img/logo-ate-blanco.svg" alt="Ate frenos">

                        <img src="/img/logo-galfer-blanco.png" alt="Galfer">


                        <a href="https://www.continental-aftermarket.com/es-es/" target="_blank" class="btn">Todo para el Aftermarket</a>

                    </div>
                </div>

            </div>
            <div class="slide">

                <div class="container">

                    <nav class="rrss-nav row-start">
                        <a href="https://www.facebook.com/continentalautomotivespain/" target="_blank"><i class="fa fa-facebook"></i></a>
                        <a href="https://twitter.com/PrensaVDO" target="_blank"><i class="fa-brands fa-x-twitter"></i></a>
                        <a href="https://www.youtube.com/user/ContiVDO" target="_blank"><i class="fa fa-youtube"></i></a>
                        <a href="https://www.linkedin.com/company/vdo-espana/" target="_blank"><i class="fa fa-linkedin"></i></a>
                    </nav>

                    <div class="imagen" data="recambios">
                        <div class="texto-imagen">
                            <h2>Recambios</h2>
                            <p>Gracias a nuestra experiencia en equipo original, ofrecemos una amplia gama de soluciones
                                para el mercado de recambio y de servicios. Bajo la marca Continental (antes VDO) éstos
                                se caracterizan por su gran precisión de ajuste y fácil instalación que abarcan desde:
                                recambios originales, diagnosis, equipamiento de taller, escobillas y baterías.</p>
                        </div>
                    </div>
                    <div class="texto column-between jcfs">
                        <div>
                            <h2>Productos en 3D</h2>
                            <p>Experimenta en 3D los productos para el aftermarket que Continental ofrece.</p>
                        </div>
                        <a target="_blank" href="https://productviewer.continental-aftermarket.com/?_gl=1*fyhg20*_ga*MTc5MTAzOTI5Ni4xNTk1MjQwNDMy*_ga_4XSTPM6XE1*MTYxMDk1OTE3OS4yLjAuMTYxMDk1OTE3OS4w" class="link row-start">
                            Visite el catálogo en 3D
                            <img src="/img/icono-3d.svg" alt="Visite el catálogo en 3D">
                        </a>
                        <a target="_blank" href="https://www.continental-aftermarket.com/es-es/productos/recambio-original/" class="btn">Descubre más</a>
                    </div>
                </div>
            </div>
            <div class="slide">

                <div class="container">

                    <nav class="rrss-nav row-start">
                        <a href="https://www.facebook.com/frenosate" target="_blank"><i class="fa fa-facebook"></i></a>
                        <a href="https://twitter.com/frenosate?lang=es" target="_blank"><i class="fa-brands fa-x-twitter"></i></a>
                        <a href="https://www.youtube.com/channel/UCBw-9_UvZHY08EYlugAY-Pg" target="_blank"><i class="fa fa-youtube"></i></a>
                    </nav>

                    <div class="imagen" data="frenos-ate">
                        <div class="texto-imagen">
                            <h2>Frenos ATE</h2>
                            <p>Componentes de freno de gama alta con la misma calidad que los equipos originales: discos
                                de freno, pinzas, líquidos de frenos y componentes electrónicos. Además de herramientas
                                y equipos de comprobación que proporcionan un servicio integral, todo en único
                                proveedor. El mejor servicio de la mano de los Centros de Freno ATE, especialistas en
                                freno.</p>
                        </div>
                    </div>
                    <div class="texto column-between jcfs">
                        <div>
                            <h2>Soluciones para el freno</h2>
                            <div class="row-start logos-frenos">
                                <a href=""><img src="/img/freno-blanco.svg" alt=""></a>
                                <a href=""><img src="/img/freno-gris.svg" alt=""></a>
                                <a href=""><img src="/img/logo-ate-blanco.svg" alt=""></a>
                                <a href=""><img src="/img/logo-ate-blanco-centro.svg" alt=""></a>
                            </div>
                        </div>
                        <a href="https://www.frenos-ate.es/" target="_blank" class="btn">Web ATE</a>
                        <a href="https://www.frenos-ate.es/servicios/centros-de-frenos-ate/" target="_blank" class="btn">Centros de Frenos ATE</a>
                    </div>
                </div>
            </div>
        </slick>

    </div>

</template>

<script>

    import Slick from 'vue-slick';

    import 'slick-carousel/slick/slick.css';

    export default {
        name: 'slider-aftermarket',
        data() {
            return {
                slickOptions: {
                    slidesToShow: 1,
                    centerMode: false,
                    arrows: false,
                    dots: true,
                    adaptiveHeight: false
                },
            };
        },
        components: { Slick },
    }
</script>