export default {
	es: {
		links : {
			quienessomos : "/quienes-somos",
			recambios : '/recambios',
			frenos : '/frenos',
			tacografos : '/tacografos',
			contacto: '/contacto',
			cookies : '/politica-de-cookies',
			saladeprensa : '/sala-de-prensa',
			avisolegal : '/aviso-legal',
			terminos : '/terminos-de-uso',
			declaracionprivacidad : '/declaracion-de-privacidad'
		}
	},
	en: {
	}
}
