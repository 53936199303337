<template>

    <div class="transporte" id="tacografos">

        <div class="container row-between">
            <h2 class="title">Todo para el transporte</h2>
        </div>

        <slick ref="slick" :options="slickOptions">
            <div class="slide">

                <div class="container">

                    <nav class="rrss-nav row-start">
                        <a target="_blank" href="https://www.facebook.com/continentalautomotivespain/"><i class="fa fa-facebook"></i></a>
                        <a target="_blank" href="https://twitter.com/PrensaVDO"><i class="fa-brands fa-x-twitter"></i></a>
                        <a target="_blank" href="https://www.youtube.com/user/ContiVDO"><i class="fa fa-youtube"></i></a>
                        <a target="_blank" href="https://www.linkedin.com/company/vdo-espana/"><i class="fa fa-linkedin"></i></a>
                    </nav>

                    <div class="texto column-between jcfs">
                        <div>
                            <h2 @click="setslide(0)" class="link">Nuestros servicios</h2>
                            <h2 @click="setslide(1)">Tacógrafos digitales e inteligente 4.0</h2>
                            <h2 @click="setslide(2)">Gestión de flotas</h2>
                            <h2 @click="setslide(3)">Red especialista en V.I</h2>
                        </div>

                        <a href="https://www.fleet.vdo.es/" target="_blank" class="btn">Todo para el transporte</a>

                    </div>

                    <div class="imagen" data="intro-tacografos">
                        <div class="texto-imagen">
                            <p>Tacógrafos digitales e inteligentes, soluciones de gestión de flotas y telemática para el
                                transporte de viajeros y mercancías, así como herramientas, equipación y productos para
                                los talleres especializados en vehículos industriales, autobuses y autocares.</p>
                        </div>
                    </div>

                </div>

            </div>
            <div class="slide">

                <div class="container">

                    <nav class="rrss-nav row-start">
                        <a target="_blank" href="https://www.facebook.com/continentalautomotivespain/"><i class="fa fa-facebook"></i></a>
                        <a target="_blank" href="https://twitter.com/PrensaVDO"><i class="fa-brands fa-x-twitter"></i></a>
                        <a target="_blank" href="https://www.youtube.com/user/ContiVDO"><i class="fa fa-youtube"></i></a>
                        <a target="_blank" href="https://www.linkedin.com/company/vdo-espana/"><i class="fa fa-linkedin"></i></a>
                    </nav>

                    <div class="texto column-between jcfs">
                        <div>
                            <h2 @click="setslide(0)">Nuestros servicios</h2>
                            <h2 @click="setslide(1)" class="link">Tacógrafos digitales e inteligente 4.0</h2>
                            <h2 @click="setslide(2)">Gestión de flotas</h2>
                            <h2 @click="setslide(3)">Red especialista en V.I</h2>
                        </div>
                        <a href="https://www.fleet.vdo.es/productos/#Tac%C3%B3grafos+y+accesorios" target="_blank" class="btn">Descubre más</a>

                    </div>

                    <div class="imagen" data="tacografos">
                        <div class="texto-imagen">
                            <h2>Tacógrafos digitales e inteligentes</h2>
                            <p>El Tacógrafo Inteligente es el núcleo en torno al cual gira una amplia gama de servicios y soluciones para una eficiente y segura gestión de datos de las flotas. Todos los componentes, compatibles entre sí, ofrecen grandes beneficios a los clientes.</p>
                        </div>
                    </div>

                </div>
            </div>
            <div class="slide">

                <div class="container">

                    <nav class="rrss-nav row-start">
                        <a target="_blank" href="https://www.facebook.com/continentalautomotivespain/"><i class="fa fa-facebook"></i></a>
                        <a target="_blank" href="https://twitter.com/PrensaVDO"><i class="fa-brands fa-x-twitter"></i></a>
                        <a target="_blank" href="https://www.youtube.com/user/ContiVDO"><i class="fa fa-youtube"></i></a>
                        <a target="_blank" href="https://www.linkedin.com/company/vdo-espana/"><i class="fa fa-linkedin"></i></a>
                    </nav>

                    <div class="texto column-between jcfs">
                        <div>
                            <h2 @click="setslide(0)">Nuestros servicios</h2>
                            <h2 @click="setslide(1)">Tacógrafos digitales e inteligente 4.0</h2>
                            <h2 @click="setslide(2)"  class="link">Gestión de flotas</h2>
                            <h2 @click="setslide(3)">Red especialista en V.I</h2>
                        </div>
                        <a href="https://www.fleet.vdo.es/servicios-tis-web/" target="_blank" class="btn">Descubre más</a>

                    </div>
                    <div class="imagen" data="gestion-flotas">
                        <div class="texto-imagen">
                            <h2>Gestión de flotas</h2>
                            <p>Gracias a las modernas soluciones telemáticas para flotas y las tecnologías digitales de información, cumplirás con las disposiciones legales de forma sencilla y segura además de reducir notablemente los tiempos de rotación, la presión en cuanto a costes y la contaminación ambiental. Te prestamos apoyo para que puedas posicionarte mucho mejor en un sector complejo a la par que dinámico, con el mejor servicio ofrecido por nuestra red de Instaladores Telemáticos.</p>
                        </div>
                    </div>

                </div>
            </div>
            <div class="slide">

                <div class="container">

                    <nav class="rrss-nav row-start">
                        <a target="_blank" href="https://www.facebook.com/continentalautomotivespain/"><i class="fa fa-facebook"></i></a>
                        <a target="_blank" href="https://twitter.com/PrensaVDO"><i class="fa-brands fa-x-twitter"></i></a>
                        <a target="_blank" href="https://www.youtube.com/user/ContiVDO"><i class="fa fa-youtube"></i></a>
                        <a target="_blank" href="https://www.linkedin.com/company/vdo-espana/"><i class="fa fa-linkedin"></i></a>
                    </nav>

                    <div class="texto column-between jcfs">
                        <div>
                            <h2 @click="setslide(0)" >Nuestros servicios</h2>
                            <h2 @click="setslide(1)">Tacógrafos digitales e inteligente 4.0</h2>
                            <h2 @click="setslide(2)">Gestión de flotas</h2>
                            <h2 @click="setslide(3)" class="link">Red especialista en V.I</h2>
                        </div>
                        <a href="https://www.fleet.vdo.es/partnerfinder/" target="_blank" class="btn">Descubre más</a>

                    </div>
                    <div class="imagen" data="red-servicios">
                        <div class="texto-imagen">
                            <h2>Red de servicios oficiales</h2>
                            <p>Nuestros servicios oficiales ofrecen asistencia profesional en torno al tacógrafo (analógico, digital e inteligente) con la seguridad de que siempre encontrarán la mejor ayuda profesional y servicio técnico. Están preparados para satisfacer las necesidades relacionadas con el tacógrafo, descarga de datos, revisiones periódicas, garantías, etc...</p>
                        </div>
                    </div>

                </div>
            </div>
        </slick>

    </div>

</template>

<script>

    import Slick from 'vue-slick';

    import 'slick-carousel/slick/slick.css';

    export default {
        name: 'slider-aftermarket',
        data() {
            return {
                slickOptions: {
                    slidesToShow: 1,
                    centerMode: false,
                    arrows: false,
                    dots: true
                },
            };
        },
        methods : {
            setslide(pos){
                this.$refs.slick.goTo(pos);
            }
        },
        components: { Slick },
    }
</script>