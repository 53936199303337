
import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    language: 'es',
    server : 'https://back.continentalautomotive.es/'
  },
  getters : {
    getServer : state => state.server,
  },  
  mutations: {
    null(){

    }
  },
  actions: {
    requestEntries: ({ commit }) => {
      return new Promise((resolve) => {
        axios.get(`/api/entries/es`).then(response => {
          resolve(response.data);
          commit('null');
        });
      });
    },
    requestEntrie: ({ commit },id) => {
      return new Promise((resolve) => {
        axios.get(`/api/entries/es${(id) ? '/'+id : ''}`).then(response => {
          resolve(response.data);
          commit('null');
        });
      });
    },
    requestCategories: ({ commit }) => {
      return new Promise((resolve) => {
        axios.get(`/api/categories/es`).then(response => {
          resolve(response.data.entries);
          commit('null');
        });
      });
    },
  },
  modules: {
  }
})
