<template>
  <div class="wrapper -home">
    <div class="encabezado">
      <div class="container">
        <h2>Soluciones globales de automoción</h2>
        <h3>Orientados a diferentes clientes</h3>
        <router-link :to="$t('links.quienessomos')" class="btn">Quiénes somos</router-link>
      </div>
    </div>

    <slider-aftermarket></slider-aftermarket>
    <slider-transporte></slider-transporte>


  </div>
</template>

<script>



  import slideraftermarket from '@/components/slider-aftermarket';
  import slidertransporte from '@/components/slider-transporte';

  export default {
    name: 'Home',
    mounted() {
      if (this.$route.params.id) {
        const offsetTop = document.querySelector("#" + this.$route.params.id).offsetTop - 50;

        scroll({
          top: offsetTop,
          behavior: "smooth"
        });
      }

    },
    components: {
      'slider-aftermarket': slideraftermarket,
      'slider-transporte': slidertransporte
    },
    watch: {
      '$route'() {
        if (this.$route.params.id) {
          const offsetTop = document.querySelector("#" + this.$route.params.id).offsetTop - 50;
          scroll({
            top: offsetTop,
            behavior: "smooth"
          });
        }


      }
    }
  }
</script>