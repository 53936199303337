import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

Vue.config.productionTip = false


//IDIOMAS
import VueI18n from 'vue-i18n'
import messages from './translations';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: store.state.language, 
  messages,
});
//.IDIOMAS

/*AXIOS*/
import axios from 'axios'
import VueAxios from 'vue-axios'
axios.defaults.baseURL = store.state.server;
Vue.use(VueAxios, axios)

import { ValidationProvider } from 'vee-validate';
import { ValidationObserver } from 'vee-validate';
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
import { extend } from 'vee-validate';
import {email,required } from 'vee-validate/dist/rules';
extend('email', {
  ...email,
  message: 'El email está mal formado'
});
extend('required', {
  ...required,
  message: 'Este campo es obligatorio'
});


import VCalendar from 'v-calendar';
Vue.use(VCalendar);

import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: "UA-196661809-1" },
});


new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
